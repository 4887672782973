





// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { LSS } from '@core/services';
import { FlowService } from '@services/flow.service';
import Vue from 'vue';
import { API_ORY_URL } from '@config'
import { SignUpService } from '@services/signup.service';
import { decodeToken } from '@shared/utils/commom.util'

export default Vue.extend({
  name: 'LoginProxy',
  methods: {
  },

  async beforeMount() {
    //console.log('v-updating-bef');
    const id = this.$route.query.id ? this.$route.query.id : '';
    const token = this.$route.query.token ? this.$route.query.token : '';
    if (id && /.+@.+\..+/.test(id) && token) {
      try {
        const vupdate = await SignUpService.updateVerified({
          Email: id,
          Password: token,
        });
      } catch (error) {
        //console.log(error);
      }
    }
  },

  async mounted () {
    //console.log('v-updating');
    const id = this.$route.query.id ? this.$route.query.id : '';
    const token = this.$route.query.token ? this.$route.query.token : '';
    if (id && /.+@.+\..+/.test(id) && token) {
      try {
        const vupdate = await SignUpService.updateVerified({
          Email: id,
          Password: token,
        });
        //console.log(vupdate);
      } catch (error) {
        //console.log(error);
      }
    }

    // to allow user to continue session after closing tab directly without logout
    // if(LSS.token && LSS.sessId){
    //   return this.$router.push('/overview');
    // }else{
    //   window.location.href = API_ORY_URL + '/self-service/login/browser'
    // }

    // if tab is closed directly and dont want to allow user to continue session
    if(LSS.token && LSS.sessId){
      LSS.clear();
      window.location.href = API_ORY_URL + 'self-service/browser/flows/logout'
    }else{
      FlowService.whoIamI()
      .then((res) => {
        if(res.error?.status == 'Unauthorized'){
          window.location.href = API_ORY_URL + 'self-service/login/browser'
        }
        else{
          window.location.href = API_ORY_URL + 'self-service/browser/flows/logout'
        }
      })
      .catch((err) => {
        //console.log(err)
      });
      //window.location.href = API_ORY_URL + 'self-service/login/browser'
    }
  }
});
